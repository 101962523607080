import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar';
import Aux from '../../../hoc/hoc';
import { ToastsStore } from 'react-toasts';


class CreateBaseOrder extends Component {
    constructor() {
        super();
        this.state = {
            elements: [],
            sel: null,
        }
        this.disabBtn=1;
        this.defWwight = ['1–2', '2–3', '3–4', '4–5', '5–6', '6–7', '7–8', '8–9', '9+'];
        this.dayVal = [{
            dayIndex: 1,
            value: 0,
        },
        {
            dayIndex: 2,
            value: 0,
        },
        {
            dayIndex: 3,
            value: 0,
        },
        {
            dayIndex: 4,
            value: 0,
        },
        {
            dayIndex: 5,
            value: 0,
        },
        {
            dayIndex: 6,
            value: 0,
        },
        {
            dayIndex: 7,
            value: 0,
        }
        ]

    }
    componentDidMount() {
        this.baseOrderMatrix(this.defWwight)

    }
    baseOrderMatrix(weigh) {
        const weighClass = weigh;
        const items = {};
        weighClass.map((data, i) => {
            items[data] = JSON.parse(JSON.stringify(this.dayVal))
        })
        this.state.elements = weighClass;
        this.state.sel = items;
        this.forceUpdate()
        console.log(this.state.sel)
    }
    onBlur = (event, a, inx) => {
        if (event.target.value === "") {
            event.target.value = 0
        }
        this.state.sel[a][inx].value = parseInt(event.target.value);
        this.disabBtn=0;
        this.forceUpdate()
    }
    createBaseOrder(){
        if(this.disabBtn==0){
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.token}`
              }
            let webApiUrl = '/customer/api/base-order/'
            var data = {"data":this.state.sel}
            axios.post(webApiUrl,data, {
                headers: headers
              })
              .then(response => {
                ToastsStore.success("Base Order Created");  
                window.location.href = '/home';
            })
            .catch((error) => {
                console.log(error)
            })
        }
        else{
            return
        }
    }



    render() {
        var daysOrder = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        return (
            <Aux>


                <section className={'body_wrap'}>
                    <Sidebar />
                    <div className={'cont_wp'}>
                        <Link to={'/home'}>
                            <p className={'Back_link'}>Back</p>
                        </Link>
                        {/* <section className={'cmn_head'}>
                            <p>Create Base Order</p>
                        </section> */}

                        <div class="scrl_out">
                            <section className={'home_top_wrap'}>
                                <div className={'home_top_block'}>
                                    <p>Create <br /> Base Order</p>
                                    <label>Make New Base Order Here</label>
                                </div>
                                <div className={'home_top_block'}>
                                <div className={'ReqBtnWrp'}>
                                        {
                                            <div className={'conBt' + ' ' + 'sp_btn'} onClick={() => this.createBaseOrder()}>
                                                {
                                                    this.disabBtn===1 && <div className={'disabOvl'}></div>
                                                }
                                                Create
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>

                            <section>
                                {
                                    this.state.elements.map(
                                        (key, i) => {
                                            return <div className={'Base_ord_wrap' + ' ' + 'qte_bg'}>
                                                <div className={'Base_ord_head' + ' ' + 'Acd_hd'}>
                                                    <section>
                                                        <span>HOG {key} kg</span>
                                                    </section>
                                                </div>
                                                <section>
                                                    {
                                                        this.state.sel[key].map((days, index) => {
                                                            return <div className={'Base_days ' + 'accHdLn '}>
                                                                <div className={'spn1'}>
                                                                    {daysOrder[index + 1]}
                                                                </div>

                                                                <input type="text" onBlur={(event) => this.onBlur(event, key, index)} placeholder="0" />
                                                            </div>
                                                        })
                                                    }
                                                </section>

                                            </div>
                                        })
                                }
                            </section>
                        </div>

                    </div>
                </section>
            </Aux>
        );
    }
};

export default CreateBaseOrder;