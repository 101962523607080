import React, { Component } from 'react';
import Aux from '../../hoc/hoc';
import axios from 'axios';
import ProceedOrder from '../OrderStatus/ProceedOrder/ProceedOrder';
import QuoteSummary from '../OrderStatus/QuoteSummary/QuoteSummary';


class OrderStatus extends Component {
    
    state = { 
        data : "Hello World" ,
        quote:true,
        list:null,
        price_id:''
    } 
    handleListData = (data,id) =>{
        this.setState(
            {list:data,
             quote:false,
             price_id:id
            }
        )
      }
    
    render() {
        if(this.state.quote){
            return(
                <Aux>
                    <QuoteSummary listData={this.handleListData}></QuoteSummary>
                </Aux>
            ) 
        }else{
            return(
                <Aux>
                    <ProceedOrder summary={this.state.list} id={this.state.price_id}></ProceedOrder>
                </Aux>
            )  
        }
    }
};

export default OrderStatus;