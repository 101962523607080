
import * as actionTypes from './actionTypes';


export const authStart = () =>{
    return{
        type:actionTypes.AUTH_START
    };
}

export const authSuccess = (token) =>{
    return{
        type:actionTypes.AUTH_SUCCESS,
        token:token,
    };
}

export const authFail = (error) =>{
    return{
        type:actionTypes.AUTH_FAIL,
        error:error
        
    };
}

export const logout = () =>{
    return{
        type:actionTypes.AUTH_LOGOUT
    };
}


export const auth = (email,password) =>{
    return dispatch =>{
        dispatch(authStart());
    };
}