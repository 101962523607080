import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import { Link } from 'react-router-dom';
import GetOtp  from '../GetOtp/GetOtp';
import ResetPassword from '../ResetPassword/ResetPassword'


class ForgotPassword extends Component {
    constructor() {
        super();   
        this.state = {
            showOtp:true,
        } 
    }
    changePassword=(status)=>{
        this.setState({
            showOtp:status
        })
        this.forceUpdate();
    }
   

    render() {
        return (
            <div className={'loginWrap'}>
                <div className={'bub_1'}>
                    <img src={require('../../../assets/images/login_bubbles.png')} alt='log_bub' />
                </div>
                <div className={'bub_2'}>
                    <img src={require('../../../assets/images/login_bubbles.png')} alt='log_bub' />
                </div>
                <div className={'bub_3'}>
                    <img src={require('../../../assets/images/login_bubbles.png')} alt='log_bub' />
                </div>
                <div className={'l_top_im'}>
                    <img src={require('../../../assets/images/t_l_im.png')} alt='log_bub' />
                </div>
                <div className={'b_rt_im'}>
                    <img src={require('../../../assets/images/b_r_im.png')} alt='log_bub' />
                </div>
                <div className={'loginOuter'}>
                    <section>
                        <div className={'logo_landing'}>
                        </div>
                        <p>Salmon across the seven seas</p>
                        <label>
                            Overseas salmon in 1-2-3
                        </label>
                        <div className={'downloadWrap'}>
                            <p>Download Our App</p>
                            <div className={'down_btn_out'}>
                                <div className={'btn_app'}>
                                    <div className={'app_ico'}></div>
                                    Download from<br /> Android App Store
                                </div>
                                <div className={'btn_app'}>
                                    <div className={'app_ico'}></div>
                                    Download from<br /> App Store
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        {
                            this.state.showOtp?<GetOtp submitOtp={this.changePassword} />:<ResetPassword />
                        }
                       
                       <Link to={'/'}>
                            <p className={'fP'}>Back To Login</p>
                        </Link> 
                    </section>
                </div>           
            </div>
        );
    }
};

export default ForgotPassword;