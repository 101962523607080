import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { Link } from 'react-router-dom';


class GetOtp extends Component {
    constructor() {
        super();
        this.state = {
            mailId: "",
            proceedContent:"",
            getOtpForm:true
        }
    }
    inputChangedHandler = (event) => {
        this.setState({
            mailId: event.target.value
        })
    }
    submitHandler = (event) => {

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(this.state.mailId) === false) {
            ToastsStore.error("Email is not correct");
        }
        else {
            const authData = {
                email: this.state.mailId,
            }
            axios.post('/customer/get_reset_password_otp/', authData)
                .then(response => {
                    this.setState({
                        getOtpForm:false,
                        proceedContent:response.data.data
                    })
                })
                .catch(error => {
                    console.log("aaa", error);
                });
        }
    }
    proceed = (event) =>{
        this.props.submitOtp(false)
    }


    render() {
        return (
            <div className={'loginFormWrap'}>
                <p className={'loginFormHead'}>Get OTP</p>
                {
                    this.state.getOtpForm?<p className={'loginFormSubHead'}>Enter your registered E-mail id to get OTP</p>:null
                }
                {
                this.state.getOtpForm?<Aux>
                    <div className={'Input'}>
                        <label>E-mail</label>
                        <input type="text" onChange={(event) => this.inputChangedHandler(event)} placeholder="Mail Address" />
                    </div>
                    <div className={'otp_btn'} onClick={(event) => this.submitHandler()}>Get OTP</div>
                </Aux>:<Aux>
                    <p className={"proceed_text"}>{this.state.proceedContent}</p>
                    <div className={'otp_btn'} onClick={(event) => this.proceed()}>Proceed</div>
                </Aux>
                }
            </div>
        );
    }
};

export default GetOtp;