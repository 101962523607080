import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import classNames from './Modal.css';

    const Modal = props => {
        return (
            <div className={'modal_overlay'}>
                <div className={'modal_body'}>
                    <section>
                        {props.children}
                    </section>
                </div>
            </div>
        );
};
export default Modal;