import React,{Component} from 'react';
import Aux from '../../../hoc/hoc';
import Sidebar from '../../Sidebar/Sidebar';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Redirect } from 'react-router-dom';
import classNames from './ProceedOrder.css';

class ProceedOrder extends Component{
    constructor() {
        super();   
        this.state = {
            days: ["Sunday", "Monday", "Tuesday" , "Wednesday" , "Thursday" , "Friday" , "Saturday"],
            countOptions:["Accept","Counter Offer","New Quote","Decline"],
            weiTotal:[],
            counterModal:false
            // price_id:''
        };
        
    }
    cancelCounter(){
        this.setState({
            counterModal:false
        })
    }
    proceedStatus(e){ 
        if(e.target.value==="Counter Offer"){
            this.setState({
                counterModal:true
            })
        }else{
            this.setState({
                counterModal:false
            })
        }
    }
    placeOrder(){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
          }
        let webApiUrl = '/price/place_order/'
        var data = {"price_request_id":this.props.id}
        axios.post(webApiUrl,data, {
            headers: headers
          })
          .then(response => {
              ToastsStore.success("Order placed successfully");
              setTimeout(() => {
                window.location.href='/price';
              }, 1000);        
        })
        .catch((error) => {
            console.log(error)
        })
    }
    render() { 
        const elements=Object.keys(this.props.summary.data)
        const sel = this.props.summary.data
        return(
            <Aux>
                <section className={'body_wrap'}>
                    <Sidebar/>
                    <div div className={'rel_div'}>
                        {
                            elements.map(
                                (key, i) => {
                                    var arrEl = sel[key].map(function(o,i) {
                                        return o.price;
                                      });
                                      var sum = arrEl.reduce(function(a, b){
                                        return a + b;
                                      }, 0);
                                    this.state.weiTotal.push(sum);
                                    console.log(this.state.weiTotal)
                                    return(
                                        
                                        <div className={'pr_block'}>
                                        <p>
                                            <span>{key}</span>
                                            {/* <div className={''}>
                                                <div></div>
                                            </div> */}
                                            {/* <select onClick={(event)=>this.proceedStatus(event,i)}>
                                            {this.state.countOptions.map(opt =>
                                                <option key={opt.key} value={opt}>{opt}</option>
                                            )}
                                            
                                            </select> */}
                                            <span>$ {this.state.weiTotal[i]}</span>
                                        </p>
                                        <span>{
                                            sel[key].map(
                                                (val,index) =>{
                                                    return(
                                                        <div> 
                                                            <div className={'pro_out'}>
                                                                <div>
                                                                    <input type="text" value={this.state.days[val.dayIndex]} disabled />
                                                                </div>
                                                                <div>
                                                                    <input type="text" value={val.price} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } 
                                            )
                                        }
                                        </span>
                                        </div>
                                    )
                                })
                        }
                        <div className={'pr_block'}>
                            <p>
                                <span>Total</span>
                                <span>$ {this.props.summary.total}</span>
                            </p>
                            <div className={'prdBtn'}>
                                <button onClick={(event)=>this.placeOrder()}>Place Order</button>
                            </div>
                        </div>
                        { this.state.counterModal ? 
                        <div className={'modalWrap'}>
                            <p>Counter Offer</p>
                            <section>
                                <div className={'i_o_wp'}>
                                    <label>Actual Price</label>
                                    <input readOnly type="text" value="4545"></input>
                                </div>
                                <div className={'i_o_wp'}>
                                    <label>Your Offer</label>
                                    <input type="text" value="4545"></input>
                                </div>
                            </section>
                            <section>
                                <button onClick={(event)=>this.cancelCounter()}>Cancel</button>
                                <button>Submit</button>
                            </section>
                        </div> : null }
                        
                    </div>
                </section>
                <ToastsContainer store={ToastsStore}/>
            </Aux>
        );
    }
};

export default ProceedOrder;