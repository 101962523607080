import React from 'react';
import Aux from '../../hoc/hoc';
import Spinner from '../../components/Ui/Spinner/Spinner';
import Sidebar from '../Sidebar/Sidebar'
// import PriceIndicator from '../PriceIndicator/PriceIndicator';

const layout = (props) =>(
    <Aux>
        <main>
            {/* <Sidebar /> */}
            {props.children}
            <Spinner></Spinner>
        </main>
    </Aux>
    
);

export default layout;