import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import className from './BaseOrder.css'

class BaseOrder extends Component {
    constructor() {
        super();
        this.state = {
            elements: [],
            sel: null,
            totalOrder: 0,
            custDetails: null,
            selectedQuote: 0,
            addrDrop: false,
            baseTog: true,
            accStat: [],
            lastElem: [],
            lastSel: null,
            lastTotal: 0,
            orderDet: {}
        }
        this.baseDetails = [];
        this.shipment_address = '';
    }

    componentDidMount() {
        this.getShipmentLocation();
        localStorage.setItem('baseStatus', 0);
        localStorage.removeItem('requestData');
        localStorage.removeItem('requestQuoteId');
        localStorage.removeItem('locationId');

    }

    getShipmentLocation() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/api/customer-shipment-location/'
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                localStorage.setItem("onBase", false);
                if (response.data.data.length) {
                    this.getBaseOrder(response.data.data[0].pk);
                    this.getLastOrder(response.data.data[0].pk);
                    this.shipment_address = response.data.data[0].fields.shipment_address;
                    localStorage.setItem('locationId', response.data.data[0].pk);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    getBaseOrder(i) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/get_base_order/'
        var data = { "customer_shipment_loc": i }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.setState({
                    elements: Object.keys(response.data.data),
                    sel: response.data.data,
                    totalOrder: response.data.total_weight
                })
                this.props.actBtn(this.state.totalOrder);

                this.state.elements.map(
                    (key, i) => {
                        this.state.sel[key].sort(function (x, y) {
                            return x['dayIndex'] - y['dayIndex'];
                        });
                    })
                this.sumAccord();
                console.log(this.state.elements);
                localStorage.setItem('requestData', JSON.stringify(response.data));
                if (this.state.elements.length != 0) {
                    localStorage.setItem("onBase", true);
                } else {
                    localStorage.setItem("onBase", false);
                }
                this.forceUpdate()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getLastOrder(i) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/get_last_order/'
        var data = { "customer_shipment_location": i }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.setState({
                    lastElem: Object.keys(response.data.order_details),
                    lastSel: response.data.order_details,
                    lastTotal: response.data,
                    orderDet: response.data.order
                })
                this.state.lastElem.map(
                    (key, i) => {
                        this.state.lastSel[key].sort(function (x, y) {
                            return x['dayIndex'] - y['dayIndex'];
                        });
                    })
                // this.forceUpdate();
                // console.log("lasr order",a)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    toggDrop() {
        this.setState({
            addrDrop: !this.state.addrDrop
        });
    }
    changeShipment = (event, a, i) => {
        this.state.selectedQuote = i;
        this.getBaseOrder(a);
        this.forceUpdate();

    }
    toggBase = () => {
        this.setState({
            baseTog: !this.state.baseTog
        })
    }
    sumAccord = () => {
        this.state.elements.map(
            (key, i) => {
                var arrEl = this.state.sel[key].map(function (o, i) {
                    return o.value;
                });
                var sum = arrEl.reduce(function (a, b) {
                    return a + b;
                }, 0);
                var accrStat = (sum === 0) ? true : false
                this.state.accStat[i] = accrStat;
            })
        // var a =this.state.lastElem.sort((x,y) => {
        //     return x['dayIndex']-y['dayIndex'];
        // })
    }
    render() {
        var daysOrder = ["", "Mon", "Tue", "Wed", "Thu", "Fr", "Sat", "Sun"]
        return (
            <Aux>
                <section className={'base_add_wrp'}>
                    <div className={'base_tog_btn'}>
                        <div className={this.state.baseTog ? "actBtnB " : null} onClick={() => this.toggBase()}>Base Order</div>
                        <div className={!this.state.baseTog ? "actBtnB " : null} onClick={() => this.toggBase()}>Last Order</div>
                    </div>
                    {
                        this.state.baseTog && <section className={'Base_wrap'}>
                            {
                                this.state.totalOrder != 0 && <Aux>
                                    <div className={'Base_block'}>
                                        <p className={'Base_head'}>Base Order</p>
                                        <div className={'Base_total_block'}>
                                            <span>{this.state.totalOrder} {this.state.totalOrder == 1 ? 'Box' : 'Boxes'}</span> <br />
                                            <label>Total Quantity</label>
                                        </div>
                                        <div className={'Base_addr_block'}>
                                            {/* <p>Shipping Address</p> */}
                                            <div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
                                                {/* <div className={'drop_arrow'}></div> */}
                                                {this.shipment_address}
                                                {/* {                                          
                                        this.state.addrDrop && <div className={'addrDrop'}>
                                        { 
                                            this.state.custDetails.map(
                                                (key, i) => {
                                                    return  <p onClick={(event)=>this.changeShipment(event, key.customer_shipment_location_id,i)}>
                                                                {key.shipment_address}
                                                            </p>
                                            })
                                        }
                                        </div>
                                    } */}
                                            </div>
                                        </div>
                                        {/* <div className={'Base_addr_block'}>
                                <p>Depature</p>
                            </div> */}
                                    </div>
                                    <div className={'Base_block'}>
                                        {
                                            this.state.elements.map(
                                                (key, i) => {

                                                    return !this.state.accStat[i] && <div className={'Base_ord_wrap'}>
                                                        <p className={'Base_ord_head'}>
                                                            HOG {key} kg
                                                </p>
                                                        <section>
                                                            {
                                                                this.state.sel[key].map((days, index) => {
                                                                    return <div key={index} className={'Base_days ' + (days.value === 0 ? "hide" : "")}>
                                                                        <div className={'hide_ovl'}></div>
                                                                        <span>{daysOrder[days.dayIndex]}</span> <br />
                                                                        <span>{days.value}</span>
                                                                        <div className={'Base_tool_tip'}>
                                                                            <div>{days.date}</div>
                                                                            {/* <div>{daysOrder[days.dayIndex]}</div> */}
                                                                            <div>{days.value} Box</div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                })
                                        }

                                    </div>
                                </Aux>
                            }
                            {
                                this.state.totalOrder == 0 && <Link className={'ed_out'} to={'/home/create-baseorder'}><div>
                                    Create Base Order
                                </div>
                                </Link>
                            }
                        </section>
                    }
                    {
                        !this.state.baseTog && <section className={'Base_wrap'}>
                            {
                                this.state.lastTotal.total_weight != 0 && <Aux>
                                    <div className={'Base_block'}>
                                        <p className={'Base_head'}>Last Order</p>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.lastTotal.total_weight} {this.state.lastTotal.total_weight == 1 ? 'Box' : 'Boxes'}</span> <br />
                                                <label>Total Quantity</label>
                                            </div>
                                        </section>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.lastTotal.total_price} USD</span> <br />
                                                <label>Total Price</label>
                                            </div>
                                        </section>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.orderDet.placed_week}</span> <br />
                                                <label>Order Week</label>
                                            </div>
                                        </section>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.orderDet.shipment_week}</span> <br />
                                                <label>Shipment Week</label>
                                            </div>
                                        </section>
                                    </div>

                                    <div className={'Base_block'}>
                                        {
                                            this.state.lastElem.map(
                                                (key, i) => {

                                                    return <div className={'Base_ord_wrap'}>
                                                        <p className={'Base_ord_head'}>
                                                            HOG {key} kg
                                                    </p>
                                                        <section>
                                                            {
                                                                this.state.lastSel[key].map((days, index) => {
                                                                    return <div key={index} className={'Base_days ' + (days.value === 0 ? "hide" : "")}>
                                                                        <div className={'hide_ovl'}></div>
                                                                        <span>{daysOrder[days.dayIndex]}</span> <br />
                                                                        <span>{days.value}</span>
                                                                        <div className={'Base_tool_tip'}>
                                                                            <div>{days.date}</div>
                                                                            {/* <div>{daysOrder[days.dayIndex]}</div> */}
                                                                            <div>{days.value} Box</div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                })
                                        }

                                    </div>
                                </Aux>
                            }
                            {
                                this.state.lastTotal.total_weight == 0 && <p className={'Nop'}>You don’t have previous orders. Please create an order from above.</p>
                            }
                        </section>
                    }
                </section>
            </Aux>
        );
    }
};

export default BaseOrder;