import React, { Component } from 'react';
import Aux from '../../hoc/hoc';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import className from './Orders.css';
import "react-datepicker/dist/react-datepicker.css";
// import axios from 'axios';



class Orders extends Component {
    constructor() {
        super();
        this.state = {
            orderList: [],
            clearDate:false
        }
        this.orderParams={
            page_size:8
        }
    }
    componentDidMount() {
        this.getOrders();
    }
    // demoClick(){
    //     this.orderParams["date_filter"]="2020-08-17"
    //     this.forceUpdate()
    //     this.getOrders()
    // }
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    dateFilter = date => {
        this.orderParams["date_filter"]=this.formatDate(date)
        // this.setState({
        //     clearDate:true
        // })
        this.forceUpdate()
        this.getOrders()
        
    };
    getOrders() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/api/placed-orders/'
        axios.get(webApiUrl, {
            params:this.orderParams,
            headers: headers
        })
            .then(response => {
                this.setState({
                    orderList: response.data.results
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <Aux>
                <section className={'body_wrap'}>
                    <Sidebar />
                    <div className={'cont_wp'}>
                        <section className={'cmn_head'}>
                            <p>Orders</p>
                            <label>View your order history here</label>
                        </section>
                        <section className={'OrderTableWrap' +' '+ 'scrl_out'}>
                            <div className={'OrderfilterPanel'}>
                                <div className={'datePickerButton'}>
                                    {/* <span>Clear</span> */}
                                    <DatePicker className={'picker'} onChange={this.dateFilter} />
                                </div>
                            </div>
                            {
                            this.state.orderList.length!=0?<table>
                                <tr className={'table_head'}>
                                    <th>Order Id</th>
                                    <th>Order date</th>
                                    <th>Total weight (kg)</th>
                                    <th>Total price</th>
                                    <th>Status</th>
                                    <th>Shipping Address</th>
                                </tr>

                                <tbody>
                                    {
                                        this.state.orderList.map((order, index) => {
                                            return <tr>
                                                <td>{order.order_id}</td>
                                                <td><Moment format="DD MMM YYYY">
                                                        {order.placed_week.split(" ")[0]}
                                                    </Moment>
                                                </td>
                                                <td>{order.total_volume?order.total_volume * 22:'---'}</td>
                                                <td>{order.total_value?"$ "+order.total_value:'---'}</td>
                                                <td>{order.status}</td>
                                                <td>{order.shipment_address_1} {order.shipment_address_2} {order.shipment_address_city}</td>
                                            </tr>
                                        })
                                    }

                                </tbody>
                            </table>:<p>You don't have any orders to list</p>
                        }
                        </section>
                    </div>
                </section>
            </Aux>
        )
    }
};

export default Orders;


