import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import Sidebar from '../../Sidebar/Sidebar';
import axios from 'axios';
import ProceedOrder from '../ProceedOrder/ProceedOrder'
import classNames from './QuoteSummary.css';

class QuoteSummary extends Component {
    constructor() {
        super();
        
        this.state = {
            items:[],
            elements: [],
            sel :null,
            days: ["Sunday", "Monday", "Tuesday" , "Wednesday" , "Thursday" , "Friday" , "Saturday"],
            proceedData:[],
            quoteSummary: null,
            edtButton:true,
            showList:false,
            price_id:''
        };
    }

    componentDidMount() {
        this.DayPriceList();
    }

    addRow = (event, key,i) => {
        var rowsNeeded = 7- this.state.sel[key].length;
        var temp = [];
        var max = Math.max.apply(Math, this.state.sel[key].map(function(o) { return o.dayIndex; }))
        for(let i=0;i<rowsNeeded;i++){
            temp.push({
                dayIndex:this.getDayIndex(key,i),
                value:"0",
                addeFromUi:true
            })
        }
        // temp.sort((x,y) => {
        //     return x['dayIndex']-y['dayIndex'];
        // })
        var  newItem = [...this.state.sel[key],...temp]
        var sel = this.state.sel;
        sel[key]=newItem
        this.setState({
            sel:sel
        })        

    }
    getDayIndex = (key,index)=>{

        var dayIndex = [0,1,2,3,4,5,6]
        var currentDayIndex = this.state.sel[key].map((value,index)=>{
            return value.dayIndex
        })
        var remaining =dayIndex.filter(d => !currentDayIndex.includes(d))
        return  remaining[index]

    } 


    DayPriceList() {
        let webApiUrl = '/price/get_last_price_for_order/'
        axios.get(webApiUrl, { headers: { "Authorization": `Bearer ${localStorage.token}` } })
            .then(response => {
                this.setState({
                    elements: Object.keys(response.data.data),
                    sel:response.data.data,
                    price_id:response.data.price_request_id
                })
                // if (this.state.sel={}){
                //     // console.log("empty")
                //     // window.location.href='/price';
                // }
            })
            
    }
    async proceedOrder(){
        console.log("aa",this.state.sel);
        console.log("bb",this.state.elements);
        
       
        // this.state.elements.map(
        //     (key, i) => {
        //         if(this.state.sel.hasOwnProperty(key)){
        //             // alert(key);
        //             this.state.sel[key].map(
        //                 (val, i) => {
        //                     // alert(val.value==="0")
        //                     this.state.sel[key].splice(val.value==="0",1)
        //                 })
        //         }
        //     })

        
        // console.log(aa)    
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
          }
        var data = {"data":this.state.sel}
        let webApiUrl = '/price/get_price_for_order/'
        const res = await axios.post(webApiUrl,data,{headers:headers})
            console.log(res.data.data)    
            this.props.listData(res.data,this.state.price_id);
            
    }


    onBlur = (event,a,index)=> {
        if(event.target.value!==""){
            if(this.state.sel.hasOwnProperty(a)){
                var ind = this.state.sel[a].findIndex( s => s.dayIndex == index )  
                if(ind!=-1){
                    this.state.sel[a][ind].value = event.target.value;
                }else{
                    this.state.sel[a].push({
                        dayIndex:index,
                        value:event.target.value
                    })                
                }
            }
            else{
                this.state.sel[a] =[{
                    dayIndex:index,
                    value:event.target.value
                }
                ]
            }
        }
       
        console.log(this.state.sel)  
    }
    render() {      
        return (
        
            <Aux>
                <section className={'body_wrap'}>
                    <Sidebar />
                    <div>
                        {
                            this.state.elements.map(
                                (key, i) => {
                                    // console.log("vv",key)
                                    // console.log(this.state.sel[key].length);
                                    // const none= this.state.sel[key]>6 ? {}:{};
                                    // console.log(none)
                                    return(
                                        
                                    <div className={'pr_block'}>
                                        <p>{key}
                                        <button onClick={(event)=>this.addRow(event,key,i)} >Edit</button>
                                        </p>
                                        <span>{
                                            this.state.sel[key].map(
                                                (val,index) =>{
                                                    return(
                                                        <div> 
                                                            <div className={'day_out'}>
                                                                <div>
                                                                    <input type="text" readOnly defaultValue={this.state.days[val.dayIndex]} />
                                                                </div>
                                                                <div>
                                                                    <input type="text" onBlur={(event)=>this.onBlur(event, key, val.dayIndex)} defaultValue={val.value} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } 
                                            )
                                        }
                                        </span>
                                        </div>
                                    )
                                })
                        }
                        <div className={'pr_block'}>
                            <div className={'prdBtn1'}>
                                <button onClick={(event)=>this.proceedOrder()}>Proceed</button>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        );
    }
};

export default QuoteSummary;