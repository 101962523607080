import React, { Component } from 'react';
import Aux from '../../hoc/hoc';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';
import className from './Users.css';


class Users extends Component {
    constructor() {
        super();
        this.state = {
            userList: [],
            customerLocations: [],
            showAddUser: false,
            first_name: '',
            last_name: '',
            email: '',
            short_name: '',
            // type: '',
            type: '2',
            password: '',
            customer_shipment_locations: [],
            shipmentLocation:[]
        }
        this.orderParams = {
            page_size: 8
        }
    }
    componentDidMount() {
        this.getUsers();
        this.getShipmentLocation();
    }

    getUsers() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = 'customer/api/customer-user/'
        axios.get(webApiUrl, {
            params: this.orderParams,
            headers: headers
        })
            .then(response => {
                console.log("ress",response.data)
                this.setState({
                    userList: response.data.results
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    showPopup() {
        this.setState({ showAddUser: true });
    }

    hidePopup() {
        this.setState({ showAddUser: false });
    }

    addUser() {
        if (!this.state.first_name || !this.state.last_name || !this.state.email || !this.state.password) {
            return;
        }
        console.log(this.state);
        let data = {
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "name_short": this.state.short_name,
            // "status": "1",
            // "customer": this.state.type,
            "status":this.state.type==2?2:1,
            "customer": this.state.type==2?Number(localStorage.getItem("customer")):null,
            "email": this.state.email,
            "password": this.state.password,
            // "customer_shipment_locations": [1, 2]
            "customer_shipment_locations": this.state.shipmentLocation
        };
        console.log(data)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = 'customer/api/customer-user/';
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.getUsers();
                this.hidePopup();
            })
            .catch((error) => {
                console.log(error)
            })
    }
    getShipmentLocation() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        // let webApiUrl = '/customer/get_customer_shipment_location/'
        // axios.get(webApiUrl, {
        //     headers: headers
        // })
        //     .then(response => {

        //         this.setState({ customerLocations: response.data.data });
        //         console.log(this.state.customerLocations);
        //         this.forceUpdate();
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     })
        let webApiUrl = '/customer/api/customer-shipment-location/'
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
               console.log("resssssssssssssr",response.data)
                this.setState({ customerLocations: response.data.results});
                console.log(this.state.customerLocations);
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    inputChange(event, type) {
        console.log(event.target.value)
        this.state[type] = event.target.value;
    }
    
    inputTypeChange=(event)=>{
        this.setState({
            type:event.target.value
        })   
    }
    handleLocationChange = (e) => {
        console.log(e.target.value)
        this.setState({
            shipmentLocation: [
              ...this.state.shipmentLocation,
              e.target.value
            ]
          })
      }

    render() {
        return (
            <Aux>
                <section className={'body_wrap'}>
                    <Sidebar />
                    <div className={'cont_wp'}>
                        <section className={'cmn_head titleSection'}>
                            <div className={'titleWrap'}>
                                <p>Users</p>
                                <label>View all users in your account</label>
                            </div>
                            <div className={'btnWrap'}>
                                <div className={'btn'} onClick={() => this.showPopup()}>Add User</div>
                            </div>
                        </section>
                        <section className={'OrderTableWrap' + ' ' + 'scrl_out'}>
                            {
                                this.state.userList.length != 0 ? <table>
                                    <tr className={'table_head'}>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Type</th>
                                        <th>Locations</th>
                                    </tr>

                                    <tbody>
                                        {
                                            this.state.userList.map((user, index) => {
                                                return <tr>
                                                    <td>{user.first_name} {user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.type == 2 ? 'Admin' : 'User'}</td> 
                                                    <td>
                                                        {user.customer_shipment_locations.map((location,i) => {
                                                            return <span className="item">{location.invoice_address_2||location.invoice_address_1}</span>
                                                        })
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        }

                                    </tbody>
                                </table> : <p>You don't have any users to list</p>
                            }
                        </section>
                    </div>
                </section>
                {this.state.showAddUser ? <section className={'popupContainer'} onClick={() => this.hidePopup()}>
                    <div className={'popupWrap'} onClick={(event) => event.stopPropagation()}>
                        <div className={'popHead'}>
                            <h3>Add User</h3>
                        </div>
                        <div className={'popBody'}>
                            <div className={'fieldWrap'}>
                                <label>First Name</label>
                                <input type="text" onChange={(event) => this.inputChange(event, 'first_name')} />
                            </div>
                            <div className={'fieldWrap'}>
                                <label>Last Name</label>
                                <input type="text" onChange={(event) => this.inputChange(event, 'last_name')} />
                            </div>
                            <div className={'fieldWrap'}>
                                <label>Short Name</label>
                                <input type="text" onChange={(event) => this.inputChange(event, 'short_name')} />
                            </div>
                            <div className={'fieldWrap'}>
                                <label>Email</label>
                                <input type="text" onChange={(event) => this.inputChange(event, 'email')} />
                            </div>
                            <div className={'fieldWrap'}>
                                <label>Account Type</label>
                                {/* <select onChange={(event) => this.inputChange(event, 'type')} value={this.state.type}> */}
                            <select onChange={(event) => this.inputTypeChange(event, 'type')} value={this.state.type}> */}
                                    {/* <option value="1">Admin</option>
                                    <option value="2">User</option> */}
                                    <option value="2">Admin</option>
                                    <option value="1">User</option>
                                </select>
                            </div>
                            <div className={'fieldWrap'}>
                                <label>Password</label>
                                <input type="password" onChange={(event) => this.inputChange(event, 'password')} />
                            </div>
                            {this.state.type==2?null:
                            <div className={'fieldWrap'}>
                                <label>Choose shipment locations</label>
                                <div className={'checkWrap'}>
                                    {/* {this.state.customerLocations.map(location => {
                                        return <div className={'checkbox'}>
                                            <input type="checkbox" name="locations" value={location.pk} id={location.pk} />
                                            <label for={location.pk}>{location.fields.country}</label>
                                        </div>
                                    })} */}
                                    
                                  
                                    {this.state.customerLocations.map(location => {
                                        return <div className={'checkbox'}>
                                            <input type="checkbox" 
                                           onChange={(e) => {
                                            this.handleLocationChange({
                                              target: {  
                                                value: location.id,
                                              },
                                            });
                                          }}
                                            name="locations" value={this.state.shipmentLocation}  />
                                            <label for={location.id}>{location.invoice_address_2||location.invoice_address_1}</label>
                                        </div>
                                    })}
                                </div>
                            </div>}
                            <div className={'btnWrap'}>
                                <div className={'btn'} onClick={() => this.addUser()}>Submit</div>
                            </div>
                        </div>
                    </div>
                </section> : null}
            </Aux>
        )
    }
};

export default Users;


