import React, { Component } from "react";
import Aux from "../../hoc/hoc";
import { Redirect, NavLink } from "react-router-dom";
import axios from "axios";
import className from "./Sidebar.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      showLogout: false,
      options: [],
      locations: [],
      prospects: []
    };
  }
  componentDidMount() {
    if (localStorage.isAdmin == "true") {
      this.getUserList();
      this.getProspects();
    }
    this.getShipmentLocation()

  }

  getShipmentLocation() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.token}`
    }
    let webApiUrl = '/customer/api/customer-shipment-location/'
    axios.get(webApiUrl, {
      headers: headers
    })
      .then(response => {
        console.log(response.data.results)
        this.setState({ locations: response.data.results })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getProspects() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.token}`
    }
    let webApiUrl = '/customer/api/prospect/'
    axios.get(webApiUrl, {
      headers: headers
    })
      .then(response => {
        console.log(response.data.results)
        this.setState({ prospects: response.data.results })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  getUserList() {
    this.setState({
      options: JSON.parse(localStorage.customerList)
    });
  }
  handleChange = e => {
    localStorage.setItem("customerLocation", e.target.value);
    localStorage.removeItem("prospect");
    window.location = "/home/";
  };
  handlePropspectChange = e => {
    localStorage.setItem("prospect", e.target.value);
    localStorage.removeItem("customerLocation");
    window.location = "/home/";
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      localStorage.clear();
      return <Redirect to="/" />;
    }
  };

  //   console.log(location.pathname);

  render() {
    const navLabels = [
      {
        label: "home"
      },
      {
        label: "orders"
      },
      {
        label: "users"
      }
      // {
      //     label:'invoices',
      // },
      // {
      //     label:'document',
      // },
      // {
      //     label:'about',
      // },
      // {
      //     label:'settings',
      // },
      // 
    ];
    const navLabels1 = [
      {
        label: "home"
      },
      {
        label: "orders"
      },
    ]

    return (
      <Aux>
        <div className={"header"}>
          {/* {localStorage.isAdmin == "true" && (
            <select
              className={"selectUserByAdmin"}
              name="selectedOption"
              value={localStorage.customerLocation}
              onChange={this.handleChange}
            >
              {this.state.options.map(i => {
                return <option value={i.loaded.c_id}>{i.customer.name}</option>;
              })}
            </select>
          )} */}

          {/* <div className={"notif_btn"}></div> */}
          {this.state.prospects.length ?
            <select
              className={"selectUserByAdmin"}
              name="selectedOption"
              value={localStorage.prospect}
              onChange={this.handlePropspectChange}
            >
              <option value=''>Select Prospect</option>
              {this.state.prospects.map(i => {
                return <option value={i.id}>{i.name}</option>;
              })}
            </select> : null
          }

          {this.state.locations.length > 1 && localStorage.isProspect == 'false' ?
            <select
              className={"selectUserByAdmin"}
              name="selectedOption"
              value={localStorage.customerLocation}
              onChange={this.handleChange}
            >
              <option value=''>Select Location</option>
              {this.state.locations.map(i => {
                return <option value={i.id}>{i.invoice_address_2 || i.invoice_address_1}</option>;
              })}
            </select> : null
          }
          <div
            className={"pro_name"}
            onClick={() =>
              this.setState({ showLogout: !this.state.showLogout })
            }
          >
            <div className={"pro_ico"}>
              {localStorage.userName ? localStorage.userName.charAt(0) : ''}
            </div>
            {localStorage.userName ? localStorage.userName : ''}
            {this.renderRedirect()}
            {this.state.showLogout && (
              <div className={"logout_pop"} onClick={this.setRedirect}>
                Logout
              </div>
            )}
          </div>
        </div>
        <div className={"side_bar_wrap"}>
          <div className="sideLogo"></div>
          <ul>
            {localStorage.isCustomerAdmin == "true" ?
              navLabels.map(function (name, index) {
                return (
                  <NavLink
                    to={"/" + name.label}
                    activeClassName={"activeNav"}
                    key={index}
                  >
                    <li>
                      <div className={"nav_ico"}></div>
                      {name.label}
                    </li>
                  </NavLink>
                );
              }) : navLabels1.map(function (name, index) {
                return (
                  <NavLink
                    to={"/" + name.label}
                    activeClassName={"activeNav"}
                    key={index}
                  >
                    <li>
                      <div className={"nav_ico"}></div>
                      {name.label}
                    </li>
                  </NavLink>
                );
              })}
          </ul>
          <div className={'sfn-logo'}></div>
        </div>
      </Aux>
    );
  }
}

export default Sidebar;
