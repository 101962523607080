import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';
import Aux from '../../../hoc/hoc';
import axios from 'axios';
import className from './PriceIndication.css'

class PriceIndication extends Component {
    constructor() {
        super();
        this.state = {
            showMore: false
        }
        this.priceList = [];
        this.moreList = [];
        this.ordWeek = '';
    }
    componentDidMount() {
        // this.getPriceIndication();
        this.getQuote();
    }

    getQuote() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/get-full/'
        let data = {};
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                console.log(response)
                this.processPrice(response.data);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    processPrice(data) {
        let key = Object.keys(data.active_admin_offer_limited);
        let dataL = data.active_admin_offer_limited;
        let more = Object.keys(data.active_admin_offer_full);
        let moreL = data.active_admin_offer_full;
        this.priceList = key.map((list) => {
            let edPrc = null;
            let prc = dataL[list]
            let chck = typeof (prc)
            if (chck == 'number') {
                edPrc = prc.toFixed(2);
            }
            else {
                edPrc = prc
            }
            // let edPrc= prc.toFixed(2);
            return <section className={'priceListBlock'}>
                <div>
                    HOG {list} kg
                             </div>
                <div>

                    {edPrc ? edPrc + ' USD' : 'NA'}
                </div>
            </section>
        })
        this.moreList = more.map(list => {
            let edPrc = null;
            let prc = moreL[list]
            let chck = typeof (prc)
            if (chck == 'number') {
                edPrc = prc.toFixed(2);
            }
            else {
                edPrc = prc
            }
            return <section className={'priceListBlock'}>
                <div>
                    HOG  {list} kg
                            </div>
                <div>
                    {edPrc ? edPrc + ' USD' : 'NA'}
                </div>
            </section>
        })
        this.forceUpdate()

    }
    toggPrice = () => {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    render() {
        return (
            <Aux>
                <section className={'body_wrap'}>
                    <Sidebar />
                    <div className={'cont_wp'}>
                        <Link to={'/home'}>
                            <p className={'Back_link'}>Back</p>
                        </Link>

                        <section className={'cmn_head'}>
                            <p>Get Price Indication</p>
                        </section>
                        {
                            localStorage.requestStatus != "under_confirmation" && localStorage.requestStatus != "confirmed" && <Link to={'/home/request'}>
                                <div className={'home_btn' + ' ' + 'prc_bu'}>
                                    {
                                        localStorage.requestQuoteStatus == "request" && <Aux>
                                            {
                                                localStorage.requestStatus == "default" && <span>Request A Quote</span>
                                            }
                                            {
                                                localStorage.requestStatus == "continue" && <span>Continue Quote</span>
                                            }
                                            {
                                                localStorage.requestStatus == "view" && <span>View Quote</span>
                                            }
                                            {
                                                localStorage.requestStatus == "quote_pending" && <span>Quote Pending</span>
                                            }
                                        </Aux>
                                    }

                                    {
                                        localStorage.requestQuoteStatus != "request" && <Aux>
                                            {
                                                localStorage.requestStatus == "default" && <span>Build Request</span>
                                            }
                                            {
                                                localStorage.requestStatus == "continue" && <span>Continue Build Request</span>
                                            }
                                            {
                                                localStorage.requestStatus == "view" && <span>View Quote</span>
                                            }
                                            {
                                                localStorage.requestStatus == "quote_pending" && <span>Quote Pending</span>
                                            }
                                        </Aux>
                                    }
                                </div>
                            </Link>
                        }

                        {
                            localStorage.requestStatus == "under_confirmation" && <Link to={'/home/checkout'}>
                                <div className={'home_btn' + ' ' + 'conf_link'}>
                                    Order Under Confirmation
                                </div>
                            </Link>
                        }
                        {
                            localStorage.requestStatus == "confirmed" && <Link to={'/home/checkout'}>
                                <div className={'home_btn' + ' ' + 'conf_link'}>
                                    View Order
                                </div>
                            </Link>
                        }

                        {
                            <Aux>

                                {/* <p className={'ship_p'}><label>Order Week</label> : <span>{this.ordWeek}</span></p> */}
                                <div class="scrl_out">
                                    <div className={'PriceWrap'}>
                                        <section className={'priceListBlock' + ' ' + 'boldHead'}>
                                            <div>
                                                Weight Class
                                        </div>
                                            <div>
                                                Indicative price
                                        </div>
                                        </section>
                                        {
                                            localStorage.indicStatus == "default" || localStorage.indicStatus == "pending" && <p className={'no_price'}>Price not available.</p>
                                        }

                                        <section className={'blk_wrp'}>

                                            <Aux>
                                                {
                                                    !this.state.showMore && this.priceList
                                                }
                                            </Aux>

                                            <Aux>
                                                {
                                                    this.state.showMore && this.moreList
                                                }
                                            </Aux>
                                            <Aux>
                                                {
                                                    this.priceList.length == 0 && this.moreList
                                                }
                                            </Aux>

                                            {
                                                this.priceList.length != 0 && <p className={'mre_btn_s'} onClick={() => this.toggPrice()}>Show {this.state.showMore ? 'Less' : 'More'}</p>
                                            }
                                        </section>
                                    </div>
                                </div>
                            </Aux>
                        }

                    </div>
                </section>
            </Aux>
        );
    }
};
export default PriceIndication;