import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import thunk from 'redux-thunk'
import reducer from './store/reducer';
import authReducer from './store/Reducers/auth';
import Axios from 'axios';

Axios.defaults.baseURL = 'http://pre.exocoetidae.no/';
// http://dev-old.exocoetidae.no/


Axios.interceptors.request.use(request => {
    // request.url = (localStorage.customerLocation)?request.url+'?customer_shipment_location='+localStorage.customerLocation:request.url



    if (request.params && localStorage.customerLocation) {
        request.params["customer_shipment_location"] = localStorage.customerLocation
        // const customerLocationArray = localStorage.customerLocation.split(',')
        // request.params["customer_shipment_location"] = customerLocationArray[0]

    }
    else if (localStorage.customerLocation) {
        request.url = request.url + '?customer_shipment_location=' + localStorage.customerLocation
        // const customerLocationArray1 = localStorage.customerLocation.split(',')
        // request.url = request.url + '?customer_shipment_location=' + customerLocationArray1[0]
    } else if (localStorage.prospect) {
        request.url = request.url + '?prospect=' + localStorage.prospect;
    } else if (localStorage.accessId) {
        request.url = request.url + '?access_id=' + localStorage.accessId;
    }
    document.body.classList.add('loading-indicator');
    return request;
}, error => {
    document.body.classList.add('loading-indicator');
    return Promise.reject(error);
});

Axios.interceptors.response.use(response => {
    document.body.classList.remove('loading-indicator');
    return response;
}, error => {
    if (error.response.status === 401) {
        ToastsStore.error(error.response.data.result);
        document.body.classList.remove('loading-indicator');
        localStorage.clear();
        window.location = '/';
    }
    else {
        ToastsStore.error(error.response.data.error);
        document.body.classList.remove('loading-indicator');
    }
    return Promise.reject(error);
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    authReducer
});
const store = createStore(reducer, composeEnhancers(
    applyMiddleware(thunk)
));
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
            <ToastsContainer position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
