import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import axios from 'axios';
import classNames from './CounterOffer.css';

class CounterOffer extends Component {
    constructor(props) {
        super(props);
        this.counterValue = 0;
        this.buttonText = 0;
    }

    updateCounterValue = (evt) => {
        if (evt.target.value != '') {
            this.counterValue = evt.target.value;
            this.buttonText = 1
        }
        else {
            this.counterValue = ''
            this.buttonText = 0
        }
        this.forceUpdate();
    }
    counterStatus = (a) => {
        if (a == 1) {
            this.props.counterStatus(a)
        }
        else {
            this.updateCounterOffer()
        }
    }
    updateCounterOffer() {
        let weighClass = this.props.counterDetails.weightClass
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee-price-line/'
        let data = {
            "quotee_id": localStorage.requestQuoteId,
            "weight_class": weighClass
        }
        if (this.counterValue) {
            data['price'] = this.counterValue;
        }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.props.counterStatus(2)
            })
            .catch((error) => {
                console.log(error)
                setTimeout(
                    function () {
                        this.props.counterStatus(1)
                    }
                        .bind(this),
                    3000
                );
            })
    }
    render() {
        return (
            <Aux>
                <div className={'modal_overlay'}>
                    <div className={'Counter_body'}>
                        <p>Ask Better Offer
                            <div className={'close_pop'} onClick={(event) => this.counterStatus(1)}></div>
                        </p>
                        <div className={'of_wrp'}>
                            <section>
                                <span>Seafood7 Price</span>
                                <div className={'prc_out'}><label className={'offer_strike'}>$ {this.props.counterDetails.price.toFixed(2)}</label></div>
                            </section>
                            <section>
                                <span>Your Offer</span>
                                <input type="text" placeholder="Optional" onChange={(event) => this.updateCounterValue(event)}></input>
                            </section>
                            <section>
                                <div className={'prc_out' + ' ' + 'ask_button' + ' ' + 'sp_btn'} onClick={(event) => this.counterStatus(2)}>
                                    {
                                        this.buttonText == 1 ? <Aux>Send Counter Offer</Aux> : <Aux>Ask Better</Aux>
                                    }
                                </div>
                            </section>
                        </div>

                        {/* <div className={'ReqBtnWrp' +' '+ 'mod_bt'}>
                            <div class="conBt" onClick={(event)=>this.counterStatus(1)}>Cancel</div>
                            <div class="conBt"  >Update</div>
                        </div> */}
                    </div>
                </div>
            </Aux>
        );
    }

};

export default CounterOffer;