import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import Sidebar from '../../Sidebar/Sidebar';
import Aux from '../../../hoc/hoc';
import BaseAccordion from '../BaseAccordion/BaseAccordion';
import className from './RequestQuote.css'

class RequestQuote extends Component {
    constructor() {
        super();
        this.state = {
            baseModAction: 0,
            selectedQuote: 0,
            addrDrop: false,
            errHan: false,
        }
        this.quoteDetailsProps = [];
        this.addrprops = [];
        this.plotAll = [];
        this.addrFromProp = null;
        this.disabBtn = 0;
        this.requestHead = 0;
        this.buildStatus = true;
    }
    handleListData = (data) => {
        data.quote['has_prices'] = data.has_prices;
        data.quote['quotee_total'] = data.quotee_total;
        data.quote['total_volume'] = data.total_volume;
        data.quote['total_weight'] = data.total_weight;
        this.quoteDetailsProps = data.quote;
        // this.addrFromProp = data.customer_shipment_data.shipment_address;
        this.forceUpdate();
    }
    buildFromProps = (buildS) => {
        this.buildStatus = buildS;
        this.forceUpdate();
        console.log(this.buildStatus)
    }
    pageHead = (data) => {
        this.requestHead = data;
        this.forceUpdate();
    }
    errorListDta = (error) => {
        this.setState({
            errHan: true,
        })
    }
    handleActBtn = (a) => {
        this.disabBtn = a;
        this.forceUpdate();
    }
    listDays = (a) => {
        console.log("days length", a)
        this.plotAll = a;
        this.forceUpdate();
    }
    accessChild = () => {
        if (this.disabBtn !== 0) {
            this.refs.child.changeText()
        }
    }
    accessChild1 = () => {
        if (this.disabBtn !== 0) {
            this.refs.child.changeText1()
        }
    }
    accessChild2 = () => {
        console.log(this.disabBtn, this.buildStatus)
        if (this.disabBtn === 0 && this.buildStatus) {
            this.refs.child.changeText2()
        }
    }
    accessChild3 = () => {
        this.refs.child.changeText3()
    }
    changeShipment = (event, a, i) => {
        this.refs.child.changeShip(a);
        this.state.selectedQuote = i;
        this.quoteDetailsProps = this.addrprops[this.state.selectedQuote];
        this.forceUpdate();

    }
    toggDrop() {
        this.setState({
            addrDrop: !this.state.addrDrop
        });
    }

    render() {
        let shipmentDays = [];
        for (let i = 0; i < this.plotAll; i++) {
            shipmentDays.push(<div className={'days_ico'}></div>)
        }
        return (
            <Aux>
                <section className={'body_wrap'}>
                    <Sidebar />
                    <div className={'cont_wp'}>
                        <Link to={'/home'}>
                            <p className={'Back_link'}>Back</p>
                        </Link>
                        <section className={'cmn_head'}>
                            {
                                this.requestHead == 0 ? <p>Request Quote</p> : <p>Build a Request</p>
                            }

                        </section>
                        {
                            !this.state.errHan && <Aux>
                                <div className={"scrl_out"}>
                                    <div className={'Modify_top'}>
                                        <section>
                                            <div className={'mn_wr'}>
                                                <p className={'Cmn_in_head'}>
                                                    Quote <span>( {this.quoteDetailsProps ?.id} )</span>
                                                </p>
                                                <label className={'Cmn_in_label'}>
                                                    Created on <Moment format="DD/MM/YYYY">
                                                        {this.quoteDetailsProps ?.created}
                                                    </Moment>
                                                    <span>|</span>
                                                    Last Update on <Moment format="DD/MM/YYYY">
                                                        {this.quoteDetailsProps ?.updated}
                                                    </Moment>
                                                </label>
                                                {/* <p className={'sp_add'}><span>Shipping Address :</span> {this.addrFromProp}</p> */}
                                            </div>
                                            <div className={'ReqBtnWrp'}>
                                                <div className={'conBt'} onClick={this.accessChild}>
                                                    {
                                                        this.disabBtn === 0 && <div className={'disabOvl'}></div>
                                                    }
                                                    Cancel
                                        </div>
                                                <div className={'conBt'} onClick={this.accessChild1}>
                                                    {
                                                        this.disabBtn === 0 && <div className={'disabOvl'}></div>
                                                    }
                                                    Update
                                                </div>
                                                {
                                                    this.quoteDetailsProps ?.has_prices == false && <div className={'conBt'} onClick={this.accessChild2}>
                                                        {
                                                            this.disabBtn !== 0 && <div className={'disabOvl'}></div>
                                                        }
                                                        {
                                                            !this.buildStatus && <div className={'disabOvl'}></div>
                                                        }
                                                        {
                                                            localStorage.onBase == 'false' ? <Aux>Send Request</Aux> : <Aux>Request Quote</Aux>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.quoteDetailsProps ?.has_prices == true && <div className={'conBt'} onClick={this.accessChild3}>Checkout</div>
                                                }

                                            </div>
                                        </section>
                                        <section>
                                            <div className={'Modify_lab_wrap'}>
                                                <div className={'mod_lab_out'}>
                                                    <div className={'mod_lab'}>
                                                        {this.quoteDetailsProps ?.total_volume > 0 ? this.quoteDetailsProps ?.total_volume : 0} {this.quoteDetailsProps.total_volume == 1 ? 'Box' : 'Boxes'}
                                                    </div>
                                                    <p>Order Total Box</p>
                                                </div>
                                                <div className={'mod_lab_out'}>
                                                    <div className={'mod_lab'}>
                                                        {this.quoteDetailsProps ?.total_volume > 0 ? this.quoteDetailsProps ?.total_weight : 0} kg
                                            </div>
                                                    <p>Order Total Weight</p>
                                                </div>
                                                <div className={'mod_lab_out'}>
                                                    <div className={'mod_lab'}>
                                                        $ {this.quoteDetailsProps ?.quotee_total > 0 ? this.quoteDetailsProps.quotee_total.toFixed(2) : 0}
                                                    </div>
                                                    <p>Order Total USD</p>
                                                </div>
                                                <div className={'mod_lab_out'}>
                                                    {
                                                        <div className={'mod_lab'}>
                                                            {this.quoteDetailsProps.shipment_week ? this.quoteDetailsProps ?.shipment_week : '---'}
                                                        </div>
                                                    }
                                                    <p>Shipment Week</p>
                                                </div>
                                                <div className={'mod_lab_out'}>
                                                    <div className={'mod_lab'}>
                                                        {this.quoteDetailsProps.quote_week ? this.quoteDetailsProps ?.quote_week : '---'}
                                                    </div>
                                                    <p>Order Week</p>
                                                </div>
                                                {/* <div className={'mod_lab_out'}>
                                            <div className={'mod_lab'}>
                                                <div className={'drop_arrow'} onClick={() => this.toggDrop()}></div>
                                                {this.addrFromProp}  
                                                {                                          
                                                this.state.addrDrop && <div className={'addrDrop'}>
                                                    { 
                                                        this.addrprops.map(
                                                            (key, i) => {
                                                                return  <p onClick={(event)=>this.changeShipment(event, key.qoute_id,i)}>
                                                                            {key.shipment_address}
                                                                        </p>
                                                        })
                                                    }
                                                    </div>
                                                }
                                            </div>
                                            
                                            <p>Shipping Address</p>
                                        </div> */}

                                                <div className={'mod_lab_out'}>
                                                    <div className={'mod_lab'}>

                                                        {this.plotAll > 0 &&
                                                            <Aux>{shipmentDays}</Aux>
                                                        }
                                                        {this.plotAll < 1 && <span>0</span>}
                                                    </div>
                                                    <p>Shipments</p>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <BaseAccordion listData={this.handleListData} buildStatusProps={this.buildFromProps} headRequest={this.pageHead} errData={this.errorListDta} actBtn={this.handleActBtn} plotDays={this.listDays} ref="child"></BaseAccordion>
                                </div>
                            </Aux>
                        }
                        {
                            this.state.errHan && <p>Unable to process the request</p>
                        }
                    </div>
                </section>
            </Aux>
        );
    }
};

export default RequestQuote;